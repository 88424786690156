<template>
  <v-touch
    v-on:swipeup="swipeup"
    v-on:swipedown="swipedown"
    :swipe-options="{ direction: 'vertical', threshold: 50 }"
    class="container"
    id="container"
  >
    <div class="top">
      <img
        class="happy"
        src="@/assets/images/commemoration/leftTop.png"
        alt=""
      />
      <img
        class="life"
        src="@/assets/images/commemoration/rightTop.png"
        alt=""
      />
    </div>
    <div class="main">
      <div class="page0" v-show="pages[0]">
        <div class="img">
          <van-image class="img2" :src="data[0].path">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
        <div class="word">
          <span class="dear" :style="getStyle(data[1])">{{
            data[1].content
          }}</span>
          <span class="tenYear" :style="getStyle(data[2])">{{
            data[2].content
          }}</span>
          <span class="time" :style="getStyle(data[3])">{{
            data[3].content
          }}</span>
        </div>
      </div>
      <div class="page1" v-show="pages[1]">
        <div class="up">
          <span class="first" :style="getStyle(data[4])">{{
            data[4].content
          }}</span>
          <span class="eng" :style="getStyle(data[5])">{{
            data[5].content
          }}</span>
        </div>
        <div class="img">
          <img
            src="@/assets/images/commemoration/img1.background.png"
            alt=""
            class="background"
          />
          <div class="IMG">
            <div class="left">
              <img :src="data[6].path" alt="" />
            </div>
            <div class="right">
              <img :src="data[7].path" alt="" />
            </div>
          </div>
          <div class="yAm">YOU & ME</div>
        </div>
        <div class="down">
          <span class="Eng" :style="getStyle(data[8])">{{
            data[8].content
          }}</span>
          <div class="b"></div>
          <span class="time" :style="getStyle(data[9])">{{
            data[9].content
          }}</span>
          <div class="b"></div>
          <span class="CN" :style="getStyle(data[10])">{{
            data[10].content
          }}</span>
        </div>
      </div>
      <div class="page2" v-show="pages[2]">
        <div class="top">
          <div class="img1">
            <img :src="data[11].path" alt="" />
          </div>
          <div class="img2">
            <img :src="data[12].path" alt="" />
          </div>
          <div class="cover">
            <img
              src="@/assets/images/commemoration/img2.cover.png"
              alt=""
              class="back"
            />
            <img
              src="@/assets/images/commemoration/img2.word.png"
              alt=""
              class="word"
            />
          </div>
        </div>
        <div class="down">
          <span :style="getStyle(data[13])">{{ data[13].content }}</span>
        </div>
      </div>
      <div class="page3" v-show="pages[3]">
        <div class="top">
          <img class="img1" :src="data[14].path" alt="" />
        </div>
        <div class="middle">
          <img class="img2" :src="data[15].path" alt="" />
          <img class="img3" :src="data[16].path" alt="" />
        </div>
        <div class="down">
          <div class="word">
            <span :style="getStyle(data[17])">{{ data[17].content }}</span>
          </div>
        </div>
      </div>
      <div class="page4" v-show="pages[4]">
        <div class="top">
          <img class="img" :src="data[18].path" alt="" />
        </div>
        <div class="word">
          <span :style="getStyle(data[19])">{{ data[19].content }}</span>
        </div>
      </div>
      <div class="page5" v-show="pages[5]">
        <div class="top">
          <span class="best" :style="getStyle(data[20])">{{
            data[20].content
          }}</span>
          <span class="eng" :style="getStyle(data[21])">{{
            data[21].content
          }}</span>
        </div>
        <div class="middle">
          <!-- <img
            src="@/assets/images/commemoration/img5.background.png"
            alt=""
            class="background"
          /> -->
          <div class="img">
            <div class="left">
              <img :src="data[22].path" alt="" />
            </div>
            <div class="right">
              <img :src="data[23].path" alt="" />
            </div>
          </div>
          <div class="word">YOU & ME</div>
        </div>
        <div class="down">
          <div class="text">
            <span :style="getStyle(data[24])">{{ data[24].content }}</span>
          </div>
        </div>
      </div>
      <div class="page6" v-show="pages[6]">
        <div class="top">
          <div class="img1">
            <img :src="data[25].path" alt="" />
          </div>
        </div>
        <div class="middle">
          <div class="word">
            <span :style="getStyle(data[26])">{{ data[26].content }}</span>
          </div>
          <div class="right">
            <div class="img2">
              <img :src="data[27].path" alt="" />
            </div>
          </div>
        </div>
        <div class="down">
          <img src="@/assets/images/commemoration/img6.down.png" alt="" />
        </div>
      </div>
      <div class="page7" v-show="pages[7]">
        <img class="img" :src="data[28].path" alt="" />
        <div class="word">
          <img
            class="bg"
            src="@/assets/images/commemoration/heart.png"
            alt=""
          />
          <span :style="getStyle(data[29])">{{ data[29].content }}</span>
        </div>
      </div>
    </div>

    <div class="end" v-show="No2">
      <img src="@/assets/images/commemoration/Happy Anniversary.png" alt="" />
    </div>
  </v-touch>
</template>

<script>
import turnPage from "@/mixins/turnPage";
import commemorationData from "@/assets/data/commemoration";
export default {
  mixins: [turnPage],
  name: "Commemoration",
  props: {
    updCurr: {
      type: Function,
    },
    list: {
      type: Array,
    },
    preview: {
      type: Boolean,
    },
  },
  data() {
    return {
      No2: 1,
      pages: [1, 0, 0, 0, 0, 0, 0, 0],
      index: 0,
      slideAnimation: "openUp",
      data: JSON.parse(JSON.stringify(commemorationData)),
    };
  },
  methods: {
    swipeup() {
      if (this.index == this.pages.length - 1) return;
      this.$set(this.pages, this.index, 0);
      this.index++;
      this.$set(this.pages, this.index, 1);
      if (this.index == 2) this.No2 = 0;
      else this.No2 = 1;
      this.updCurr && this.updCurr(this.index + 1);
    },
    swipedown() {
      if (this.index == 0) return;
      this.$set(this.pages, this.index, 0);
      this.index--;
      this.$set(this.pages, this.index, 1);
      if (this.index == 2) this.No2 = 0;
      else this.No2 = 1;
      this.updCurr && this.updCurr(this.index + 1);
    },
    getStyle(data) {
      let percent =
        this.$route.name == "display" ||
        this.preview ||
        this.$route.name == "finished"
          ? 1
          : 0.6;
      return {
        fontFamily: data.fontFamily,
        fontSize: data.fontSize * percent + "px",
        color: data.fontColor,
        lineHeight: data.fontLineHeight * percent + "px",
        letterSpacing: data.fontSpacing * percent + "px",
        whiteSpace: "pre-wrap",
      };
    },
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        if (this.list) {
          this.data = this.list;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

span{
  opacity: .8;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("~@/assets/images/commemoration/background.png");
  background-size: cover;
  padding: 20% 0 10%;
  overflow: hidden;

  > .top {
    position: absolute;
    width: 100%;
    height: 7%;
    padding: 3% 1% 0 5%;
    display: flex;
    justify-content: space-between;
    top: 0;

    .happy {
      height: 100%;
    }

    .life {
      height: 100%;
    }
  }

  .main {
    width: 100%;
    height: 90%;

    .page0 {
      display: flex;
      width: 100%;
      height: 100%;
      color: #ffe4c7;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .img {
        animation: bottom 1s ease-in-out;
        width: 80%;
        padding: 2%;
        background-color: #fff;
        margin-bottom: 10%;
        img {
          width: 98%;
        }
      }
      .word {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        animation: fale 1s ease-in-out;

        .tenYear {
          font-weight: 600;
          margin: 15% 0;
        }
      }
    }
    .page1 {
      display: flex;
      width: 100%;
      height: 100%;
      color: #ffe4c7;
      text-align: center;
      align-items: center;
      flex-direction: column;
      .up {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        animation: fale 1s ease-in-out;

        .eng {
          margin: 14% 0;
        }
        flex: 2;
      }

      .img {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        justify-content: center;
        margin-bottom: 10%;

        .background {
          position: absolute;
          width: 100%;
          height: 100%;
          animation: right 1s ease-in-out;
        }
        .IMG {
          position: relative;
          z-index: 1;
          margin-top: 10%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          .left {
            width: 40%;
            animation: left 1s ease-in-out;
            img {
              width: 100%;
            }
          }
          .right {
            width: 40%;
            animation: bottom 1s ease-in-out;
            img {
              width: 100%;
            }
          }
        }

        .yAm {
          position: relative;
          z-index: 1;
          color: #000;
          letter-spacing: 6px;
          font-size: 20px;
          margin: 10% 0;
          font-weight: bold;
          animation: fale 1s ease-in-out;
        }
        flex: 6;
      }
      .down {
        display: flex;
        flex-direction: column;
        justify-content: center;
        animation: bottom 1s ease-in-out;

        .Eng,
        .time,
        .CN {
          font-size: 5vw;
          font-weight: 100;
          letter-spacing: 1px;
        }
        .time {
          margin: 10px 0;
          padding: 10px 0;
          border-top: 1px solid #ffe4c7;
          border-bottom: 1px solid #ffe4c7;
        }
        // .b {
        //   height: 1px;
        //   margin: 3% auto 2%;
        //   background-color: #ffe4c7;
        // }
        flex: 3;
      }
    }
    .page2 {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-top: 20%;
      .top {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-right: 10%;
        padding-bottom: 6%;
        width: 100%;
        height: 100%;
        .img1 {
          width: 70%;
          background-color: #fff;
          padding: 1%;
          margin-bottom: 4%;
          animation: right 1s ease-in-out;

          img {
            width: 100%;
          }
        }
        .img2 {
          width: 65%;
          padding: 1%;
          background-color: #fff;
          animation: bottom 1s ease-in-out;

          img {
            width: 100%;
          }
        }
        .cover {
          position: absolute;
          width: 54%;
          height: 100%;
          top: -7%;
          left: 0%;
          animation: left 1s ease-in-out;
          .back {
            width: 100%;
            height: 96%;
          }
          .word {
            position: absolute;
            top: 50%;
            left: 20%;
            width: 20%;
            transform: translateY(-50%);
          }
        }
        flex: 4;
      }
      .down {
        display: flex;
        align-items: center;
        margin-left: 10%;
        color: #ffe4c7;
        flex: 2;
        animation: fale 1s ease-in-out;
      }
    }
    .page3 {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: space-evenly;
      .top {
        width: 80%;
        animation: right 1s ease-in-out;

        .img1 {
          width: 100%;
          border: 8px solid #fff;
        }
      }
      .middle {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin: 5% 0;

        & > img {
          border: 8px solid #fff;
        }

        .img2 {
          animation: left 1s ease-in-out;
          width: 50%;
        }
        .img3 {
          margin: auto 0;
          width: 42%;
          animation: bottom 1s ease-in-out;
        }
      }
      .down {
        .word {
          text-align: center;
          color: #ffe4c7;
          line-height: 7vw;
          animation: fale 1s ease-in-out;

          p {
            font-size: 5vw;
          }
        }
      }
    }
    .page4 {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding-top: 5%;

      .top {
        width: 82%;
        animation: bottom 1s ease-in-out;
        margin-bottom: 10%;

        .img {
          width: 100%;
          box-shadow: -32px -32px 0 -15px #ffe4c7, 32px 32px 0 -15px #fff;
        }
      }

      .word {
        text-align: center;
        color: #ffe4c7;
        line-height: 200%;
        animation: right 1s ease-in-out;

        p {
          font-size: 5vw;
          font-weight: 400;
        }
      }
    }
    .page5 {
      display: flex;
      width: 100%;
      color: #ffe4c7;
      text-align: center;
      flex-direction: column;
      justify-content: space-evenly;
      position: relative;

      .top {
        display: flex;
        flex-direction: column;
        animation: bottom 1s ease-in-out;

        .best {
          font-weight: 400;
        }
        .eng {
          margin: 5% 0;
        }
      }
      .middle {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15% 0;
        background-image: url("~@/assets/images/commemoration/img5.background.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 5%;

        .background {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
          animation: left 1s ease-in-out;
        }
        .img {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .left {
            position: relative;
            z-index: 1;
            width: 40%;
            animation: bottom 1s ease-in-out;

            img {
              width: 100%;
            }
          }
          .right {
            position: relative;
            z-index: 1;
            width: 40%;
            animation: right 1s ease-in-out;

            img {
              width: 100%;
            }
          }
        }
        .word {
          position: relative;
          z-index: 1;
          color: #000;
          letter-spacing: 3vw;
          animation: fale 1s ease-in-out;
          margin-top: 5%;
        }
      }
      .down {
        width: 100%;
        margin: 0 auto;

        .text {
          width: 88%;
          text-align: right;
          animation: fale 1s ease-in-out;

          p {
            line-height: 8vw;
            letter-spacing: 1vw;
            font-weight: 100;
          }
        }
      }
    }
    .page6 {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      color: #ffe4c7;
      text-align: right;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 6%;
      .top {
        .img1 {
          margin-left: 8%;
          width: 55%;
          padding: 1%;
          background-color: #fff;
          animation: left 1s ease-in-out;
          img {
            width: 100%;
          }
        }
      }
      .middle {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .word {
          animation: bottom 1s ease-in-out;
          margin-top: 10%;

          p {
            line-height: 200%;
            letter-spacing: 0.5vw;
            font-weight: 100;
          }
        }
        .right {
          width: 40%;
          z-index: 1;
          margin: -15% 0 0 -5%;
          animation: right 1s ease-in-out;

          .img2 {
            background-color: #fff;
            padding: 2%;
            img {
              width: 100%;
            }
          }
        }
      }

      .down {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 37%;
        top: 80%;
        left: 0%;
        z-index: 0;
        animation: right 1s ease-in-out;

        img {
          width: 145%;
          height: 160%;
          overflow: hidden;
        }
      }
    }
    .page7 {
      display: flex;
      width: 100%;
      height: 100%;
      color: #ffe4c7;
      text-align: center;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .img {
        width: 84%;
        border-radius: 500px 500px 0 0;
        border: 12px solid #ffe4c7;
        animation: bottom 1s ease-in-out;
      }
      .word {
        width: 100%;
        font-size: 5vw;
        line-height: 150%;
        font-weight: 100;
        letter-spacing: 2px;
        animation: left 1s ease-in-out;
        position: relative;

        .bg {
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          z-index: 0;
        }

        span{
          display: inline-block;
          margin-top: 10%;
          position: relative;
          z-index: 1;
        }
      }
    }
  }

  .end {
    margin: 0 auto;
    width: 45%;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);

    img{
      width: 100%;
    }
  }
}

@keyframes fale {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes left {
  0% {
    opacity: 0.2;
    transform: translateX(-100vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes right {
  0% {
    opacity: 0.2;
    transform: translateX(100vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.3);
  }
  66% {
    transform: scale(1.2);
  }
  33%,
  100% {
    transform: scale(1);
  }
}

@keyframes bottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>